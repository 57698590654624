import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


export const getbuilder = createAsyncThunk("products/fetchBuilders", async (_, { rejectWithValue })=>{
    try {
        const response = await axios.get("https://omniinfraheights.com/admin/public/api/builders");
        return response.data; // Fulfilled response
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || "Something went wrong!")
    }
})

const BuilderSlice = createSlice({
    name:'builder',
    initialState:{
        builderLoading:false,
        builders:[],
        isError:false,
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getbuilder.pending, (state)=>{
            state.builderLoading = true;
        })
        .addCase(getbuilder.fulfilled, (state, action)=>{
            state.builderLoading = false;
            state.builders = action.payload;
        })

        .addCase(getbuilder.rejected, (state, action)=>{
            state.builderLoading = true;
            state.builders = action.payload;
        })
    }
})

export default BuilderSlice.reducer