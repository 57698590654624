import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


export const fetchProduct = createAsyncThunk("products/fetchProducts", async (_, { rejectWithValue })=>{
    try {
        const response = await axios.get("https://omniinfraheights.com/admin/public/api/list-listing");
        return response.data; // Fulfilled response
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || "Something went wrong!")
    }
})

export const fetchSingleProduct = createAsyncThunk(
    "singleProduct",
    async (id, { rejectWithValue }) => {
      try {
        const response = await axios.get(`https://omniinfraheights.com/admin/public/api/listing/${id}`);
        return response.data; // Axios automatically parses JSON
      } catch (error) {
        // Handle errors
        return rejectWithValue(
          error.response?.data?.message || "Something went wrong"
        );
      }
    }
  );


const productSlice = createSlice({
    name:'product',
    initialState:{
        productLoading: false,
        products: [],
        singleProduct: [],
        filterproduct:[],
        searchFilterdata:[],
        isError: false,
        errorMessage: '', // Optional: store an error message
    },
   
    reducers:{
        
        fetchcatedata: (state, action)=>{
         
            const productlist = state.products.data;
            const catValue = action.payload;
            const filteredProducts = productlist.filter(item => Number(item.category_id) === Number(catValue));
            state.filterproduct = filteredProducts;
        },
        fetchProjectData:(state, action)=>{
            const productlist = state.products.data;
            const projectId = action.payload;
            const filteredProducts = productlist.filter(item => Number(item.projects_id) === Number(projectId));
            state.filterproduct = filteredProducts;
        },
        fetchBuilderData:(state, action)=>{
            const productlist = state.products.data;
            const builderId = action.payload;
            const filteredProducts = productlist.filter(item => Number(item.builder) === Number(builderId));
            state.filterproduct = filteredProducts;
        },
        fetchcityProduct: (state, action)=>{
            const productlist = state.products.data;
            const cityValue = action.payload;
            const filteredProducts = productlist.filter(item => item.city.toLowerCase() === cityValue);
            state.filterproduct = filteredProducts;
        },
        // bookingfilter:(state, action) =>{
          
        //     const productlist = state.products.data;  // products is an array
        //     const searchvalue = action.payload;
        //     const getSearchfilterproduct = productlist?.filter(item => 
        //     item.city.toLowerCase() === searchvalue.city.toLowerCase() && item.state.toLowerCase() === searchvalue.state.toLowerCase());
        //     state.searchFilterdata = getSearchfilterproduct;
                
        // },
        fetchSelectedData:(state, action)=>{
           
            const productlist = state.products.data;
            const searchvalue = action.payload;
            state.searchFilterdata = productlist
            
            if(searchvalue.city){
                state.searchFilterdata = state.searchFilterdata.filter(item => item.city.toLowerCase() === searchvalue.city.toLowerCase());
                
            }
            if(searchvalue.bhk){
                state.searchFilterdata = state.searchFilterdata.filter(item => item.unit_type === searchvalue.bhk);
                
            }
            if(searchvalue.segment){
                state.searchFilterdata = state.searchFilterdata.filter(item => item.segment.toLowerCase() === searchvalue.segment.toLowerCase());
            }
            if(searchvalue.state){
                state.searchFilterdata = state.searchFilterdata.filter(item => item.state.toLowerCase() === searchvalue.state.toLowerCase());
                
            }

        },
        
    },
    extraReducers:(builder) =>{
        builder
        .addCase(fetchProduct.pending, (state) => {
            state.productLoading = true;
            
        })
        .addCase(fetchProduct.fulfilled, (state, action) => {
            
            state.productLoading = false;
            state.products = action.payload; 
 
        })
        .addCase(fetchProduct.rejected, (state, action) => {
            state.productLoading = true;
            state.products = action.payload; 
           
        });

        builder.addCase(fetchSingleProduct.pending, (state, action) =>{
            state.productLoading= true;
        })
        builder.addCase(fetchSingleProduct.fulfilled, (state, action) => {
            state.productLoading = false;
            state.singleProduct = action.payload; // Assuming action.payload is the product data directly
        });
        builder.addCase(fetchSingleProduct.rejected, (state, action)=>{
            state.isError= true;
            state.singleProduct = action.payload;
        })

    }
})



export default productSlice.reducer
export const {fetchcityProduct, fetchcatedata, bookingfilter, fetchSelectedData, fetchProjectData, fetchBuilderData} = productSlice.actions