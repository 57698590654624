
import { lazy, Suspense, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
// import HomePage from './pages/home/HomePage'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.min.js'
import './styles/style.css'
// import Listing from './pages/listing/Listing'
// import Detail from './pages/detail/Detail.js'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCategory } from './redux/CategorySlice.js'
import Register from './component/register/Register.js'
import { fetchProduct } from './redux/ProductSlice.js'
// import About from './pages/about/About.jsx'
// import Contact from './pages/contact/Contact.js'
// import Privacy from './pages/privacypolicy/Privacy.js'
// import Termscondition from './pages/termsandcondition/Termscondition.js'
// import Notfoundpage from './pages/pagenotfound/Notfoundpage.js'
import ScrollToTop from './component/scrolltop/ScrollToTop.js'
import { startLoading, stopLoading } from './redux/LoaderSlice.js'
import SiteLoader from './component/siteloader/SiteLoader.js'
import { getbuilder } from './redux/BuilderSlice.js'
import { getProject } from './redux/ProjectSlice.js'
import { fetchGallery } from './redux/GallerySlice.js'
// import Gallery from './pages/gallery/Gallery.js'
// import Career from './pages/careers/Career.js'
import { fetchBlog } from './redux/BlogSlice.js'

const HomePage = lazy(()=>import('./pages/home/HomePage'))
const About = lazy(()=>import('./pages/about/About'))
const Contact = lazy(()=>import('./pages/contact/Contact'))
const Privacy = lazy(()=>import('./pages/privacypolicy/Privacy'))
const Termscondition = lazy(()=>import('./pages/termsandcondition/Termscondition'))
const Gallery = lazy(()=>import('./pages/gallery/Gallery'))
const Career = lazy(()=>import('./pages/careers/Career'))
const Notfoundpage = lazy(()=>import('./pages/pagenotfound/Notfoundpage'))
const Listing = lazy(()=>import('./pages/listing/Listing'))
const Detail = lazy(()=>import('./pages/detail/Detail'))


const App = () => {

  const location = useLocation();
  const dispatch = useDispatch()
  const isLoading = useSelector((state) => state.loader.isLoading);
  

  useEffect(()=>{
    dispatch(fetchProduct())
    dispatch(fetchCategory())
    dispatch(getbuilder())
    dispatch(getProject())
    dispatch(fetchGallery())
    dispatch(fetchBlog())

  },[dispatch])

  useEffect(() => {
    dispatch(startLoading());

    // Mimic an async operation, like fetching data or rendering components.
    const timeoutId = setTimeout(() => {
      dispatch(stopLoading());
    }, 1000); // You can adjust the duration or trigger based on when data is fetched.

    return () => clearTimeout(timeoutId);
  }, [location, dispatch]);


  return (
    <>
    <div>
      {/* {isLoading && <SiteLoader />} */}
      {/* Your routes and other components go here */}
    </div>
      <ScrollToTop />
        <Register/>
      <Suspense fallback={<SiteLoader />}>
        <Routes>
          <Route  exact={true} path='/' element={<HomePage  />} /> 
          <Route  exact={true} path='/listing/:city' element={<Listing  />} /> 
          <Route  exact={true} path='/listing/:id' element={<Listing  />} /> 
          <Route  exact={true} path='/detail/:id' element={<Detail/>} />
          <Route  path='/listing' element={<Listing />} /> 
          <Route  exact={true} path='/detail/:id' element={<Detail/>} /> 
          <Route exact={true} path='/detail' element={<Detail/>} /> 
          <Route exact={true} path='/about' element={<About />} /> 
          <Route exact={true} path='/gallery' element={<Gallery/>} /> 
          <Route exact={true} path='/contact' element={<Contact/>} /> 
          <Route exact={true} path='/career' element={<Career/>} /> 
          <Route exact={true} path='/privacy' element={<Privacy/>} /> 
          <Route exact={true} path='/terms' element={<Termscondition/>} />
          <Route path="*" element={<Notfoundpage />} />
        </Routes>
      </Suspense>
    </>
  )
}

export default App