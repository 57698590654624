import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


export const fetchBlog = createAsyncThunk('fetch/fetchBlogs', async(_, {rejectWithValue})=>{
   
    try {
       
        const response = await axios.get('https://omniinfraheights.com/blog/wp-json/custom/v1/latest-posts')
        return response.data
    } catch (error) {
        if(axios.isAxiosError(error)){
            return rejectWithValue(error.response?.data?.message || "Something went wrong!");
        }

        return rejectWithValue("An unknown error occurred!");
    }
})
const BlogSlice = createSlice({
    name:'blog',
    initialState:{
        blogLoading:false,
        blogs:[],
        isError:false,
    },
    reducers:{},
    extraReducers:(builder) =>{
        builder.addCase(fetchBlog.pending, (state) => {
            state.blogLoading = true;
            console.log("Fetching blogs..."); // Debug log
        });
        builder.addCase(fetchBlog.fulfilled, (state, action) => {
            state.blogLoading = false;
            state.blogs = action.payload;
            console.log("Fetched blogs:", action.payload); // Debug log
        });
        builder.addCase(fetchBlog.rejected, (state, action) => {
            state.isError = true;
            console.error("Error fetching blogs:", action.error); // Debug log
        });
    }
})


export default BlogSlice.reducer