import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchCategory = createAsyncThunk('fetch/category', async(_, {rejectWithValue})=>{
   
    try {
       
        const response = await axios.get('https://omniinfraheights.com/admin/public/api/categories')
        return response.data
    } catch (error) {
        if(axios.isAxiosError(error)){
            return rejectWithValue(error.response?.data?.message || "Something went wrong!");
        }

        return rejectWithValue("An unknown error occurred!");
    }
})

const CategorySlice = createSlice({
    name:'categories',
    initialState:{
        categoryLoading:false,
        categories:[],
        isError:false,
    },
    reducers:{},
    extraReducers:(builder) =>{
        builder.addCase(fetchCategory.pending, (state, action)=>{
            state.categoryLoading = true
        })
        builder.addCase(fetchCategory.fulfilled, (state, action)=>{
            state.categoryLoading = false;
            state.categories = action.payload
        })
        builder.addCase(fetchCategory.rejected, (state, action)=>{
            state.isError = true;
            state.categories = action.payload
        })
    }
})


export default CategorySlice.reducer